:root {
  --white: #f0f2f5;
  --black: #1d1e1f;
}

/* @import '~antd/dist/antd.css'; */
@import './wcm.css';
@import './site.css';
@import './quest.css';
@import './layout.css';
@import './colors.css';

.bgSignUp {
  background-color: #0D0B0B !important;
  padding-bottom: 24px !important;
  height: 90vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-repeat: repeat-y;
  overflow-x: hidden;
  overflow-y: scroll;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.97) 0%, rgba(0, 0, 0, 0.65) 100%), url("../images/bgLogin@3x.png");
}

.fieldPlacement {
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: center;
}

.editLabel {
  text-align: left;
  margin-top: 18px;
  font-weight: 500;
  color: black;
  display: block;
}

.editField {
  padding: 4px;
  width: 80%;
  border-radius: 4px;
  border: solid 1px black;
  background-color: white;
  color: black;
}

.signUpField {
  display: flex;
  padding: 2px;
  width: 275px;
  height: 28px;
  margin-left: 20px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
  background-color: #211E1A;
  color: white;
}

.reason {
  display: flex;
  width: 570px;
  height: 42px;
  padding: 8px;
  margin-left: 20px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.45);
  background-color: rgba(28, 32, 28, 0.03);
  color: white;
}

.institutionField {
  display: flex;
  padding: 8px;
  width: 570px;
  margin-left: 20px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
  background-color: rgba(29, 34, 36, 0);
  color: white;
}

.buttonSignUp {
  width: 275px;
  margin: 10px 0 10px 20px;
  height: 36px;
  border-radius: 5px;
  background-color: #DDC7B0;
  color: black;
  font-weight: 600;
  border: none;
}

.buttonSignUp:hover {
  background-color: silver;
  color: black;
}

.labelName {
  font-weight: 500;
  text-align: left;
  color: white;
  display: block;
}

label {
  margin-left: 20px;
  color: white;
}

label.ant-form-item-required {
  color: white;
}

label.editLabel {
  margin-left: 55px;
}

.signUpLogo {
  height: 90px;
  margin-top: 8px;
  object-fit: contain;
}

.signUpText {
  margin-top: 28px;
  margin-bottom: 36px;
  font-size: 18px;
}

.logInText {
  margin-top: 10px;
  margin-bottom: 42px;
  font-size: 14px;
}

.show-help {
  margin-left: 24px;
}

.ant-form-item-explain {
  margin-left: 7%;
}

/*Log in screen */

.bgLogIn {
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../images/login.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
  z-index: 1000;
}

.pass-modal {
  background-color: black !important;
}

.reset-logo {
  flex: 1;
  height: 24vh;
}

.reset-form {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.outer {
  margin: 12vh auto;
  padding: 0.5rem;
  /* width: 45%; */
}

.inner {
  display: flex;
  align-items: center;
  align-content: space-around;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  padding: 12px;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.logInLogo {
  width: 42px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.logInField {
  display: flex;
  padding: 4px;
  width: 380px;
  margin-left: 20px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
  background-color: #000000;
  color: white;
}

.position {
  margin-left: 22rem;
}

.museumLogo {
  margin-right: 90rem;
  width: auto;
  height: 150px;
}

@media (max-width: 1230px) {
  .museumLogo {
    display: none;
  }

  .logInLogo {
    width: 42px;
    height: auto;
    margin-top: 170px;
    margin-bottom: 8px;
    object-fit: contain;
  }
}

.formField {
  display: flex;
  padding: 4px;
  width: 270px;
  margin-left: 20px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #707070;
  background-color: white;
  color: black;
}

.switchField {
  display: flex;
  padding: 4px;
  width: 80px;
  margin-left: 20px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
  background-color: #000000;
  color: white;
}

.ant-form-item-label>label.switchLogin {
  color: white;
}

.link {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 26px;
  color: white;
}

.link:hover {
  color: #AA9024;
  font-weight: 600;
}

.buttonLogIn {
  width: 378px;
  border: none;
  margin-left: 20px;
  height: 34px;
  border-radius: 4px;
  background-color: beige;
  color: black;
  font-weight: 500;
}

.buttonLogIn:hover {
  background-color: silver;
  color: black;
}

/* Reset screen */

.bgReset {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.97) 0%, rgba(0, 0, 0, 0.53) 100%), url("../images/bgLogin@3x.png"); */
  background-image: url("../images/login.png");
  height: 100vh;
  text-align: center;
}

.resetLogo {
  width: 675px;
  height: 160px;
  margin-top: 60px;
  margin-bottom: 10px;
  object-fit: contain;
}

.resetForm {
  width: 50%;
  margin: 0 auto;
}

.resetText {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 18px;
}

.resetField {
  display: flex;
  padding: 8px;
  width: 378px;
  margin-top: 10px;
  margin-left: 20px;
  height: 52px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
  background-color: #000000;
  color: #f0f2f5;
}

/* menu*/

.ant-menu {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: none;
  padding: 0 8px !important;
}

.ant-menu-horizontal {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: none;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-item {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: 1px solid #999 !important;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-item-only-child {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: 1px solid #999 !important;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-horizontal>.ant-menu-item-selected {
  background-color: #ddc7b0 !important;
  color: var(--white) !important;
  border-bottom: 1px solid #847214 !important;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-horizontal>.ant-menu-item:hover {
  background-color: #c6ae94 !important;
  color: var(--white) !important;
  border-bottom: 1px solid #999 !important;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-submenu-horizontal {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: 1px solid #999 !important;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-submenu {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-bottom: none;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-submenu-selected {
  background-color: #ddc7b0 !important;
  color: var(--white) !important;
  border-bottom: none;
  padding: 0 8px !important;
  margin: 0 !important;
}

.ant-menu-vertical>.ant-menu-item-selected {
  color: var(--white) !important;
  background-color: #ddc7b0 !important;
}

.ant-menu-vertical>.ant-menu-item:hover {
  color: var(--white) !important;
  background-color: #c6ae94 !important;
}

/* menu*/

.logOutButton {
  /* width: 100px; */
  height: 30px;
  background-color: #99836C;
  color: white;
}

.logOutButton:hover {
  background-color: #C6AE94;
  color: white;
  border: none;
}

/*
 ** home page
 */

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(52, 106, 180, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 7px rgba(52, 106, 180, 0.0);
  }
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-animation: pulse 1.5s infinite ease-out;
  animation: pulse 1.5s infinite ease-out;
  background: #ED0C37;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
}

.dot:before {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.4);
}

.dot:nth-child(odd) {
  -webkit-animation: pulse 1.5s infinite ease-out 0.3s;
  animation: pulse 1.5s infinite ease-out 0.3s;
}

@media (min-width: 768px) {
  .dot {
    width: 16px;
    height: 16px;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0px 0px 0px 0px #ED0C37;
    }

    100% {
      box-shadow: 0px 0px 0px 7px rgba(52, 106, 180, 0);
    }
  }
}

.infoButton {
  color: #211E1A;
  background-color: #ddc7b0;
  font-weight: 500;
  border: 1px solid black;
}

.infoButton:hover {
  background-color: #BCAB98;
  color: white;
  border: 1px solid black;
}

.headerButton {
  height: 30px;
  border-radius: 4px;
  color: #211E1A;
  background-color: #ddc7b0;
  font-weight: 500;
  margin: 5px
}

.headerButton:hover {
  color: white;
  background-color: #bcab98;
}

.tableButton {
  min-width: 80%;
  border-radius: 4px;
  color: #211E1A;
  background-color: #ddc7b0;
  border: 1px solid #bcab98;
  font-weight: 500;
  margin: 2px auto;
}

.tableButton:hover {
  background-color: #BCAB98 !important;
  color: white;
  border: none;
}

.filterButton {
  width: 80%;
  border-radius: 4px;
  color: #211E1A;
  background-color: #ddc7b0;
  border: 1px solid #bcab98;
  font-weight: 500;
  margin: 5px
}

.filterButton:hover {
  background-color: #BCAB98;
  color: white;
  border: none;
}

.ant-radio-button-wrapper {
  background: #eee !important;
  border: 1px solid black !important;
}

.ant-radio-button-wrapper-checked {
  background: #333 !important;
  border: 1px solid black !important;
}

.tableBarTitle.ant-btn-link {
  color: black;
  font-weight: 500;
  border: none;
}

.tableBarTitle.ant-btn-link:hover {
  color: #DDC7B0;
}

.tableBarTitle.ant-btn-ghost:hover {
  color: #DDC7B0;
  border: black 1px solid;
}

.buttonUser {
  width: 150px;
  height: 33px;
  border-radius: 33px;
  background-color: black;
  color: white;
}

.buttonUser:hover {
  background-color: white;
  color: black;
  border: none;
}

/* conflict field */

.conflictField {
  display: flex;
  height: 40px;
  padding: 4px;
  width: 375px;
  margin-left: 20px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.38);
}

.steps-content {
  margin-top: 16px;
  background-color: white;
  text-align: center;
}

.steps-action {
  margin-top: 30px;
}

.ant-steps {
  background-color: #0D0B0B;
  height: 80px;
  border-radius: 4px;
  padding-top: 20px;
}

.conflictField {
  display: flex;
  width: 545px;
  height: 45px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.38);
  background-color: #ffffff;
  font-weight: 500;
}

/*
Step component
*/

.stepFormField {
  color: black;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #211E1A;
  background-color: #ffffff;
  width: 100%;
  vertical-align: center;
}

.transFormField {
  color: black;
  height: 32px;
  /* border-radius: 4px;
  border: solid 1px #211E1A;
  background-color: #ffffff; */
  width: 100%;
  vertical-align: center;
}

.partFormField {
  color: black;
  height: 36px;
  margin: 0;
  border-radius: 4px;
  border: solid 1px #211E1A;
  background-color: #fafaff;
  width: 100%;
  vertical-align: center;
}

.oralFormField {
  float: left;
  color: black;
  height: 27px;
  border-radius: 4px;
  border: solid 1px #211E1A;
  background-color: #ffffff;
  width: 100%;
  vertical-align: center;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
  margin-right: 160px;
  color: white;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: white;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #DDC7B0;
  border: none;
  font-weight: 500;
  color: black;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  background-color: white;
  color: black;
  border: none;
  font-weight: 500;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: white;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #6faf6f;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #6faf6f;
  border: none;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #6faf6f;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: black;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 20px;
}

/*
Detail view elements and navbar
*/
.ant-card-body {
  min-height: 250px;
  padding: 10px 15px;
}

.ant-input:disabled {
  background-color: #fffafa;
  color: #666;
}

.ant-layout {
  background-color: white;
}

.ant-card:hover {
  box-shadow: 0 0 6px rgba(33, 33, 33, .2);
}

.ant-tag {
  margin: 4px;
  padding: 4px;
  border: 1px solid black;
  border-radius: 15px;
}

.ant-table {
  border: 1px solid #666;
  margin-top: 24px;
}

.ant-table th {
  background-color: #666 !important;
  color: white !important;
}

.ant-table tr {
  background-color: #efefef !important;
  cursor: pointer;
}

.ant-table tr:nth-child(2n+1) {
  background-color: #e0e0e0 !important;
}

.ant-table td {
  padding: 2px 8px !important;
  border-right: 1px solid #999;
  /* height: 48px !important; */
}

.ant-table td:last-child {
  padding: 2px 8px !important;
  border-right: none;
}