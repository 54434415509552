.card-container {
  width: 90%;
  height: 80vh;
  margin: auto;
  padding: 1%;
  background-color: aliceblue;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.card-item {
  width: auto;
  height: auto;
  margin: 4px;
  flex: 1;
  flex-basis: 9%;
  cursor: pointer;
}