.layout {
  padding-bottom: 12px;
  overflow-x: hidden;
  overflow-y: auto;
}

.users-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 98%;
  min-height: 100vh;
  text-align: center;
  font-weight: 400;
}

.users-side {
  flex: 1;
  background-color: black;
  color: white;
  text-align: left;
  padding: 18px;
}

.users-side h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 18px 0;
}

.users-main {
  flex: 4.5;
  padding: 0 0 36px 18px;
}

.side-label {
  color: white;
}

.side-group {
  font-size: 1.1rem;
  margin: 9px 0 9px 0;
}

.side-button {
  font-size: 1.1rem;
  padding: 4px 12px;
  height: 36px;
}

.timer {
  background-color: black;
  color: white;
  padding: 4px;
}

.edit-history-button {
  float: right;
  text-align: right;
  margin-top: -24px;
  margin-bottom: 12px;
  font-size: 0.8em;
  cursor: pointer;
}

.imaginary {
  width: 80%;
  border: 1px solid grey;
  padding: 12px;
  font-size: 1rem;
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
}

.lc-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 48px;
}

.lc-card {
  display: block;
  flex: 1;
  border: 1px solid darkgray;
  background-color: lightgray;
  margin: 8px;
  padding: 8px;
}

.lc-item {
  min-height: 32px;
  border: 1px solid black;
  background-color: white;
  margin: 8px;
  padding: 4px 8px;
  font-size: 1rem;
}

.input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pre-text {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
  font-size: 1rem !important;
}