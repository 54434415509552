.quest {
  font-family: Arial, 'Segoe UI', Tahoma, sans-serif;
  font-size: 12px;
  background-color: #fff;
  color: black !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  padding: 24px;
}

.quest-card {
  margin: 12px auto;
  text-align: justify;
  font-family: Arial, 'Segoe UI', Tahoma, sans-serif;
}

.quest-nav {
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .quest-card {
    width: 100%;
    padding: 24px;
  }

  .quest-nav {
    width: 100%;
    padding-top: 24px;
  }      
}

@media only screen and (min-width: 900px) {
  .quest-card {
    width: 90%;
    padding: 24px;
    border: 1px solid black;
  }

  .quest-nav {
    width: 100%;
    padding-top: 24px;
  }      
}

.quest-title {
  font-family: Arial, 'Segoe UI', Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.quest-button {
  font-family: Arial, 'Segoe UI', Tahoma, sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  height: 36px;
  margin-top: 12px;
  border-radius: 6px;
}

.ant-divider {
  border: 1px solid lightgrey;
}

.ant-radio {
  text-align: left;
  width: 16px;
  height: 16px;
  color: black !important;
  border: 1px solid black;
  border-radius: 50%;
}

.ant-input {
  color: black;
  border: 1px solid black;
}

.ant-input-number {
  color: black;
  border: 1px solid black;
}

.ant-select {
  color: black;
  border: none;
  border-bottom: 1px solid black;
}

.quest-radio {
  /* border: 1px solid black !important; */
  color: black !important;
}

.ant-radio-input {
  /* border: 1px solid black !important; */
  color: black !important;
}

.quest-label {
  font-family: Arial, 'Segoe UI', Tahoma, sans-serif;
  font-size: 12px;
  text-decoration: italic;
  text-align: left;
  color: black;
  background-color: #eee;
  padding: 4px;
  margin-bottom: 4px;
}

.quest-table {
  display: table;
  width: 100%;
}

.quest-input {
  font-size: 12px;
  border: none;
  color: black;
  border-bottom: 1px solid black;
}