body {
  font-family: Roboto, Verdana, 'Segoe UI', Tahoma, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-top: 66px;
  padding-bottom: 120px;
  margin: 0;
  background-color: white;
}

header {
  position: fixed;
  top: 0;
  height: 54px;
  text-align: center;
  color: #f0f2f5;
  background-color: #1d1e1f;
  padding: 0 !important;
  width: 100%;
  z-index: 999;
}

footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  height: 32px;
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 4px;
  color: #f0f2f5;
  background-color: #1d1e1f;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

hr {
  border: 1px #AA9024 solid;
  margin: 6px 0;
}

.subtitle {
  font-size: 1.5em;
  margin-left: 24px;
}

.flag {
  padding: 4px;
  width: 5rem;
  border: 1px solid #333;
  background-color: #DDC7B0;
  font-size: 1.1em;
  font-weight: 600;
}

.resLabel {
  font-size: small !important;
  color: red !important;
}

.divider {
  border: 1px #AA9024 solid;
  margin: 8px 0;
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
  width: 98%;
  padding-bottom: 36px;
  text-align: center;
  font-weight: 450;
  background-color: white;
}

.step {
  margin: 0 auto;
  width: 92%;
  text-align: center;
  font-weight: 450;
  background-color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
  opacity: .75;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.profileLayout {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-width: 80%;
  padding-bottom: 36px;
  text-align: center;
  font-weight: 450;
  background-color: white;
}

.separator {
  clear: both;
  margin: 12px 0;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  margin: 0 auto;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-auto-rows: 10px;
}

.image-list img {
  width: 94%;
  grid-row-end: span 2;
}

.image-list img:hover {
  border: 2px blue solid;
  cursor: pointer;
}

/* .marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
} */

.ant-col {
  padding: 0 4px;
}

.ant-upload {
  background-color: #faffff;
  padding: 0 8px;
}

.upload {
  width: 80% !important;
  height: 140px !important;
  background-color: #faffff;
  margin-top: 12px;
  padding: 0 8px;
  border: 1px solid black !important;
}

.media-upload {
  width: 80% !important;
  height: 66px !important;
}

.audio {
  width: 100%;
  height: 32px;
  /* border: 1px solid black; */
}

.tabpane {
  font-size: 1.5em !important;
  width: 120px !important;
}

.imgWrap {
  position: relative;
  display: table;
  cursor: pointer;
}

.imgDesc {
  height: 100%;
  width: 94%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: -16px 0 0 8px;
  background: rgba(99, 87, 74, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  padding: 12px;
  text-align: left;
  align-items: top;
  justify-content: left;
  transition: opacity .2s, visibility .2s;
}

.imgText {
  font-size: 0.8vw;
  height: 80%;
  overflow: hidden;
}

.imgWrap:hover .imgDesc {
  visibility: visible;
  opacity: 1;
}

.imgButt {
  position: absolute;
  bottom: 0;
  left: 12px;
  margin-bottom: 6px;
  visibility: hidden;
}

.imgButton {
  width: 45%;
  font-size: 0.8vw;
}

.imgWrap:hover .imgButt {
  visibility: visible;
  opacity: 1;
}

.imgDesc {
  transition: .2s;
  transform: translateY(1em);
}

.imgWrap:hover .img__Ddes {
  transform: translateY(0);
}

/*---------ADVANCED FILTER---------*/

.filterLabel {
  text-align: left;
  font-size: 5px !important;
  color: blue !important;
  display: block;
}

.filterField {
  text-align: left;
  border-radius: 4px;
  border: solid 1px black;
  background-color: white;
  color: black;
}

.filterInput {
  font-family: Verdana;
  font-size: 11px;
  padding: 2px;
  height: 32px;
  /* margin-bottom: -48px !important; */
}

.ant-select-selector {
  border: 1px solid #423607;
}

.ant-slider-rail {
  border: 1px solid #423607;
  background-color: white;
}

.ant-slider-track {
  border: 1px solid #423607;
  background-color: #847214 !important;
}

.ant-slider-handle {
  border: 1px solid #423607;
}

.ant-checkbox {
  border: 1px solid #423607;
}

.ant-picker {
  border: 1px solid #423607;
}

/* .ant-picker-input { } */

.filterRadio {
  font-family: Verdana;
  font-size: 11px !important;
  padding: 2px !important;
  margin-bottom: -48px !important;
}

.filter-navigator {
  background-color: '#eaeaee';
  padding: 8px 0;
  margin-top: -12px;
}

.filterButton {
  float: left;
  width: 96px;
  height: 32px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 400;
  border: 1px solid #bcab98;
  background-color: #DDC7B0;
  color: black;
  margin: 0 8px;
}

.filterConfirm {
  background-color: black !important;
  color: white !important;
}

.filterReset {
  background-color: maroon !important;
  color: white !important;
}

.filterButton:hover {
  background-color: #BCAB98;
  color: white;
  border: 1px solid #bcab98;
}

.filterButton:disabled {
  color: black;
  background-color: lightgrey;
  border: 1px solid grey;
}

.dragger {
  margin: 0 auto !important;
  width: 150px !important;
  height: 150px !important;
  border: 1px solid #369 !important;
}

.tabFrame {
  text-align: left;
  margin-left: -48px;
}

.tabPane {
  /* margin-left: -64px; */
  width: 100% !important;
}

.cardContainer {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 64px;
  text-align: center;
}

/*---------* VIDEO FRAME *----------*/

.antCard {
  float: left;
  text-align: center;
  cursor: pointer;
  margin: 1% !important;
  width: 18%;
  height: 250px;
  border: 1px solid #369;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}


.video-container {
  position: relative;
  padding-bottom: 50%;
  /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.statistika {
  position: absolute;
  bottom: 32px;
  left: 0;
}

.onestat {
  text-align: center;
  width: 120px;
  height: 60px;
  border: 1px solid lightgrey;
  background-color: white;
  color: black;
  margin: 6px;
}

/*---------*---------*/

.row-dragging {
  background: grey;
  color: white;
  border: 1px solid black;
}

.row-dragging td {
  padding-right: 16px;
  visibility: visible;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.user-item {
  margin: 12px 0;
  cursor: pointer;
}

.user-item:hover {
  color: red;
}

.restrictions {
  color: red;
  border: 1px red solid;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px;
  text-align: center;
  font-size: 1.2em;
}

.check-button {
  border: 1px solid #369;
  width: 80px;
  height: 60px;
  background-color: #faffff;
}

.link-button {
  color: #bcab98;
  border: 1px solid #bcab98;
  padding: 8px;
  width: 164px;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-modal-close-x .ant-modal-close-x {
  padding: 24px !important;
}

.story-frame {
  text-align: left;
  overflow: auto;
  width: 100%;
  height: 218px;
  padding: 4px;
  border: 1px solid lightgray;
}

.ant-upload-drag-container {
  height: 90px !important;
}

.jodit-container {
  width: 100vw !important;
}

.no-report {
  float: right;
  /* width: 40px; */
  text-align: center;
  padding: 4px 8px;
  background-color: red;
  color: white;
  border-radius: 4px;
}

.req-report {
  float: right;
  /* width: 40px; */
  text-align: center;
  padding: 4px 8px;
  background-color: red;
  color: white;
  border-radius: 4px;
}

.yes-report {
  float: right;
  /* width: 40px; */
  text-align: center;
  padding: 4px 8px;
  background-color: green;
  color: white;
  border-radius: 4px;
}

.ttl-report {
  float: left;
  font-size: 1.2em;
  padding-top: 4px;
}

.pdf-report {
  font-weight: 600;
  text-align: center;
  padding: 4px;
  border: 1px solid green;
  border-radius: 4px;
  color: green;
  cursor: pointer;
}

.reportCard {
  float: left;
  text-align: center;
  margin: 24px 1%;
  width: 48%;
  min-height: 500px;
  border: 1px solid #369;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.reportContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

reportButton {
  flex: 1;
}

.searchBar {
  width: 160px;
  height: 36px;
  margin-top: 4px;
  border-radius: 4px;
  text-align: left;
}

a:hover {
  color: black !important;
}

.message {
  margin: 0 auto;
  border: 2px solid black;
  color: white;
}

.success {
  background-color: green;
  cursor: pointer;
}

.error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
  background-color: red;
  border: 1px solid white;
  color: white;
  margin: 8px auto;
  padding: 8px;
}

.user-switch {
  margin-bottom: -3px;
}

.head-link {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 26px;
  color: white !important;
  cursor: pointer;
}

.head-link:hover {
  color: #ceceff !important;
}

.white-label {
  color: white !important;
  vertical-align: middle;
  text-align: right;
}

.logo-bw {
  width: 104px;
  height: 222px;
}

.pin-title {
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 12px;
}

.pin-text {
  color: white;
  font-size: 1em;
  text-align: left; 
}

.rst-text {
  color: white;
  font-size: 1em;
  text-align: center; 
}

.pass-valid {
  width: 80%;
  margin: 0 auto;
  text-align: left;
}

.login-form {
  padding-top: 18px;
  color: white;
  margin-left: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  padding-top: 6px;
  background-color: #e0e0e0;
  margin: 8px 0;
  overflow: hidden;
}

.row:nth-child(odd) {
  background-color: #fffafa;
}

.col {
  text-align: left;
  vertical-align: middle;
  padding: 4px;
  margin: 4px;
  font-size: 0.95em;
  overflow: hidden;
}

.ico {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  margin: 0 4px;
  font-size: 1.25em;
  cursor: pointer;
}